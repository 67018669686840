import React from 'react'
import './Dummy.scss'

const DummyNav = () => {
  return (
    <div className='dummy'>
        dcd
    </div>
  )
}

export default DummyNav