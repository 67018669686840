import React from 'react'
import './TopBanner.modul.scss';

const TopBanner = () => {
  return (
    <>
        <section className='hem_topBannerMain_div'>
            <img className='hem_topBanner_img' src='https://cdnfs.optigoapps.com/content-global3/hemratnaj4TSFKRAE5XUD96V2L/B2CStoreFrontEndBanners/hemratnajewelsxv/mainbanner/1.jpg'  />
        </section>
    </>
  )
}

export default TopBanner