import React from 'react';
import B2bCart from "./CartPageB2c/Cart";

const CartMain = () => {

    return (
        <div>
            <B2bCart />
        </div>
    );
};

export default CartMain;
