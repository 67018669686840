import React from 'react'
import Wish1 from "./Wish1/Wishlist"
import Wish2 from "./Wish2/Wishlist"

const MainWish = () => {
    return (
        <div>
            <Wish2 />
        </div>
    )
}

export default MainWish