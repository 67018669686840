import React from 'react'
import "./WebLoder.scss"

const WebLoder = () => {
    return (
        <div class="for_spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default WebLoder